.chat-box {
    position: fixed;
    bottom: 20px;
    right: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 18px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
  }
  
  .chat-box input,
  .chat-box textarea {
    width: calc(100% - 20px);
    margin-bottom: 10px;
    
  }
  .chat-box textarea {
    height: 200px; /* Set the desired height */
    border: none; /* Change the border color */
    border-radius: 5px;
    padding: 5px;

   /* Adjust padding as needed */
    resize: none;
  }


  
  .chat-box button {
    background-color: var( --text-color);
    color: var(--text-dark);
    border: none;
    padding: 5px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .chat-box button:hover {
    background-color: var(--text-medium);
    color: white;
  }

  .minimized-indicator {
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 22%; 
    height: 25px; 
    background-color: var( --text-color); 
    border-radius: 2px;
    cursor: pointer;
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
  }
  .minimized-indicator button {
    background-color: var(--border-color);
    color: var(--text-dark);
    border: none;
    padding: 3px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .minimized-indicator:hover {
    background-color:var(--text-medium);
  }

  .minimized {
    height: 0; /* Minimized state */
    overflow: hidden; /* Hide contents when minimized */
  }