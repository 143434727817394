@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Mukta:wght@200;300;400;500;600;700;800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

:root {
  --morning-color: #6fabf2;
  --afternoon-color: #ff8717;
  --evening-color: #61a143;
  --night-color: #04435f;
  --primary-color: #6fabf2;
  --text-dark: #202124;
  --text-medium: #4d4b4b;
  --border-color: #dadce0;
  --text-color: #9e9e9e;
  --bg-color: #f4f5f7;
  --fc-daygrid-event-dot-width: 8px;
}

@font-face {
  font-family: productSans400;
  src: url("./assets/fonts/Product\ Sans\ Regular.ttf");
}

@font-face {
  font-family: productSans500;
  src: url("./assets/fonts/Product\ Sans\ Bold.ttf");
}

* {
  font-family: "Inter", sans-serif !important;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

input {
  border: none;
  outline: none;
}

input::placeholder {
  color: var(--border-color);
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
  overflow: hidden !important;
}

a {
  text-decoration: none !important;
}
p {
  color: var(--text-medium);
}

.page-title {
  color: var(--text-dark);
  font-size: 1.4rem;
  font-weight: 600 !important;
}

.previous-next-button {
  background-color: var(--primary-color);
  width: 34px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
}
.date-change-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.MuiTableCell-root {
  font-size: 12px;
  color: var(--text-medium);
  padding-right: 0;
}
.MuiTable-root {
  border: 1px solid var(--border-color);
  border-left: none;
  border-right: none;
}
.MuiPaper-root {
  box-shadow: none !important;
  border-right: none !important;
}

.MuiTableCell-root {
  color: var(--text-medium) !important;
  font-size: 14px !important;
  padding: 0 7px !important;
  height: 50px;
}

::-webkit-scrollbar {
  width: 0;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  cursor: pointer;
}

.input-container {
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  height: 45px;
  border-color: var(--border-color);
  cursor: pointer;
  margin-top: 10px;
}

.input-label {
  font-size: 14px;
  color: var(--text-medium);
  width: 15%;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-field {
  border: none;
  outline: none;
  color: var(--text-medium);
  font-size: 14px;
  width: 80%;
  height: 94%;
  cursor: pointer;
  margin-left: 10px;
}

select {
  width: 30%;
  padding: 10px;
  border: none;
  /* border-radius: 5px; */
  background-color: #ffffff;
  font-size: 16px;
  color: #555;
  appearance: none; /* remove default arrow */
}

select:focus {
  outline: none;
  border-color: var(--primary-color); /* change border color on focus */
}

.customFileType::-webkit-file-upload-button {
  border: none;

  text-align: center;
  display: none;
  font-size: 20px;
  background-color: #f9f9f9 !important;
  color: #555 !important;
}

.employee-list-container {
  height: 60vh;
  overflow-y: scroll;
  margin-top: 2px;
  width: 100%;
}

.employee-list > div:hover {
  background-color: var(--bg-color);
  border-radius: 5px;
}

.fonticon09 {
  color: var(--primary-color) !important;
  font-size: 20px !important;
  padding-top: 0px;
  margin-left: -30px;
}

/* input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
input[type="time"]::-webkit-datetime-edit-hour-field {
  display: none;
}
input[type="time"]::-webkit-datetime-edit-minute-field {
  display: none;
}
input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
} */

input[type="time"] {
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cls-6 {
  background: #000 !important;
}

.select__value-container {
  padding: 0 !important;
}

.dashboard_employee_search_input {
  border: 1px solid var(--border-color);
  font-size: 12px;
  padding: 10px;
  width: 92.5%;
  border-radius: 5px;
}
.dashboard_employee_search_input::placeholder {
  color: var(--text-color);
  font-size: 12px;
}

.rdrMonthAndYearPickers select {
  width: 100%;
  font-size: 12px;
}
.rdrMonthAndYearWrapper .rdrMonthAndYearPickers {
  justify-content: space-between;
}

.justify_between {
  display: flex;
  justify-content: space-between;
}
.justify_center {
  display: flex;
  justify-content: center;
}
.align_center {
  display: flex;
  align-items: center;
}
